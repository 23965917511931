import useAccount from './useAccount'

const useRefreshToken = () => {
  const { setAccount } = useAccount()

  const refresh = async (axios) => {
    try {
      const response = await axios.post('/auth/refresh')
      setAccount((account) => {
        return {
          ...account,
          activeTenant: response?.data.activeTenant,
          accessToken: response?.data.accessToken,
          expiration: response?.data.expiration,
          tenants: response?.data.tenants,
          name: response?.data.name,
          lastname: response?.data.lastname,
        }
      })
      return response?.data.accessToken
    } catch (error) {}
  }
  return refresh
}

export default useRefreshToken
