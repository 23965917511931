import axiosInstance from '../services/axios'
import useAccount from './useAccount'

const useLogout = () => {
  const { setAccount } = useAccount()

  const logout = async () => {
    setAccount({})
    try {
      await axiosInstance.post('/auth/logout')
    } catch (error) {
      console.log(error)
    }
  }

  return logout
}

export default useLogout
