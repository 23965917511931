import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    background: {
      default: '#EFF1F5',
    },
    primary: {
      main: '#5BC5C6',
      dark: '#00AFAF',
    },
    secondary: {
      main: '#434E65',
    },
    appbar: {
      background: '#ffffff',
      nameColor: '#656A80',
      tenantColor: '#5BC5C6',
      border: '1px solid #DEDFE8',
      shadow: '0 2px 6px 0 rgb(0 0 0 / 4%), 0 6px 22px rgb(0 0 0 / 5%)',
      avatarBorder: '2px solid #fff',
      avatarShadow: '0px 0px 0px 1px #5BC5C6',
    },
    sidebar: {
      background: '#fff',
      link: '#656A80',
      hoverLinkBackground: '#EDF0F7',
      hoverLink: '#5BC5C6',
      border: '1px solid #DEDFE8',
      shadow: '0 2px 6px 0 rgb(0 0 0 / 4%), 0 6px 22px rgb(0 0 0 / 5%)',
    },
  },
  typography: {
    allVariants: {
      fontFamily: ['Inter', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(
        ','
      ),
    },
    h1: {
      fontSize: '2.5rem',
      color: '#434E65',
    },
    h2: {
      fontSize: '2rem',
      color: '#434E65',
    },
    h3: {
      fontSize: '1.2rem',
      fontWeight: 600,
      color: '#434E65',
    },
    h4: {
      fontSize: '1rem',
      fontWeight: 500,
      color: '#656A80',
    },
    h5: {
      fontSize: '1rem',
      fontWeight: 300,
      color: '#717b91',
    },
    h6: {
      fontSize: '0.8rem',
      fontWeight: 300,
      color: '#717b91',
    },
    dialogItemTitle: {
      fontSize: '0.9rem',
      fontWeight: 300,
      color: '#717b91',
    },
    dialogItem: {
      fontSize: '1rem',
      fontWeight: 400,
      color: '#434E65',
    },
    text1: {
      fontSize: '0.9rem',
      fontWeight: 400,
      color: '#434E65',
      textAlign: 'right',
    },
    text2: {
      fontSize: '1rem',
      fontWeight: 500,
      color: '#434E65',
    },
    text3: {
      fontSize: '1rem',
      fontWeight: 400,
      color: '#656A80',
    },
    text4: {
      fontSize: '1rem',
      fontWeight: 400,
      color: '#656A80',
    },
    attendanceHeader1: {
      color: '#656A80',
      fontSize: '0.85rem',
      '@media (min-width:700px)': {
        fontSize: '1rem',
      },
      '@media (min-width:900px)': {
        fontSize: '0.85rem',
      },
      '@media (min-width:1000px)': {
        fontSize: '1rem',
      },
      fontWeight: 400,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    attendanceHeader2: {
      color: '#656A80',
      fontSize: '0.8rem',
      fontWeight: 400,
    },
    form_error: {
      fontSize: '0.75rem',
      fontWeight: 400,
      color: '#d32f2f',
    },
    attendanceSum: {
      color: '#656A80',
      fontSize: '1rem',
      fontWeight: 400,
    },
  },

  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#434E65',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          height: '64px',
          minHeight: '64px',
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'text', color: 'primary' },
          style: {
            '&:active': { backgroundColor: '#5bc4c632', color: '#00AFAF' },
            '&:hover': { backgroundColor: '#5bc4c632', color: '#6c8d8d' },
          },
        },
        {
          props: { variant: 'text', color: 'error' },
          style: {
            '&:hover': { backgroundColor: '#d32f2f1d', color: '#d32f2f' },
          },
        },
        {
          props: { variant: 'text', color: 'warning' },
          style: {
            color: '#d3842f',
            '&:hover': { backgroundColor: '#c88f2424', color: '#c3721b' },
          },
        },
        {
          props: { variant: 'text', color: 'success' },
          style: {
            color: '#10c036',
            '&:hover': { backgroundColor: '#5bc66932', color: '#009f22' },
          },
        },
        {
          props: { variant: 'contained', color: 'primary' },
          style: {
            backgroundColor: '#5BC5C6',
            color: '#ffffff',
            boxShadow: 'none',
            '&:hover': {
              backgroundColor: '#00AFAF',
              color: '#ffffff',
              boxShadow: 'none',
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'primary' },
          style: {
            '&:hover': { backgroundColor: '#434E65', color: '#ffffff' },
          },
        },
      ],
    },
    MuiIconButton: {
      variants: [
        {
          props: { color: 'primary' },
          style: {
            backgroundColor: '#5BC5C6',
            color: '#ffffff',
            '&:hover': { backgroundColor: '#00AFAF', color: '#ffffff' },
            '& ion-icon': { fontSize: '0.9rem' },
          },
        },
      ],
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          color: '#656A80',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          color: '#656A80',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          textarea: { color: '#656A80' },
          input: { color: '#656A80' },
        },
      },
    },
  },
  body: {},
})

export default theme
