import { Outlet } from 'react-router-dom'
import { useState, useEffect } from 'react'
import useRefreshToken from '../hooks/useRefreshToken'
import useAccount from '../hooks/useAccount'
import { CircularProgress, Grid } from '@mui/material'
import useAxios from '../hooks/useAxios'

const PersistentLogin = () => {
  const [loading, setLoading] = useState(true)
  const axiosInstance = useAxios()
  const refresh = useRefreshToken()
  const { account } = useAccount()

  useEffect(() => {
    const verifyRefreshToken = async () => {
      try {
        await refresh(axiosInstance)
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    }

    if (!account?.accessToken) {
      verifyRefreshToken()
    } else {
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {}, [loading])

  return (
    <>
      {loading ? (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '100vh' }}
        >
          <Grid item xs={12}>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : (
        <Outlet />
      )}
    </>
  )
}

export default PersistentLogin
