import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { AccountProvider } from './context/AccountProvider'
import { ThemeProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import { BrowserRouter } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import theme from './layout/Theme'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <AccountProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          <App />
        </SnackbarProvider>
      </ThemeProvider>
    </AccountProvider>
  </BrowserRouter>
  // </React.StrictMode>
)
