import axiosInstance from '../services/axios'
import { useEffect } from 'react'
import useRefreshToken from './useRefreshToken'
import useAccount from './useAccount'
import { useNavigate } from 'react-router-dom'

const useAxios = () => {
  const refresh = useRefreshToken()
  const { account, setAccount } = useAccount()
  const navigate = useNavigate()

  useEffect(() => {
    const requestIntercept = axiosInstance.interceptors.request.use(
      (config) => {
        if (!config.headers.Authorization) {
          config.headers.Authorization = `Bearer ${account?.accessToken}`
        }
        return config
      },
      (error) => Promise.reject(error)
    )

    const responseIntercept = axiosInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error?.config
        if (error?.response?.status === 403 && !originalRequest?.sent) {
          originalRequest.sent = true
          const newAccessToken = await refresh(axiosInstance)
          originalRequest.headers.Authorization = `Bearer ${newAccessToken}`

          return axiosInstance(originalRequest)
        }
        if (error?.response?.status === 401 && !originalRequest?.sent) {
          navigate('/login')
        }
        return Promise.reject(error)
      }
    )
    return () => {
      axiosInstance.interceptors.request.eject(requestIntercept)
      axiosInstance.interceptors.response.eject(responseIntercept)
    }
  }, [account, setAccount, refresh, navigate])

  return axiosInstance
}

export default useAxios
