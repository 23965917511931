import {
  AppBar,
  Avatar,
  Box,
  CircularProgress,
  Drawer,
  FormControl,
  IconButton,
  InputLabel,
  Link,
  List,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  Toolbar,
  useMediaQuery,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Outlet, useLocation, Link as RouterLink } from 'react-router-dom'
import useAccount from '../hooks/useAccount'
import useLogout from '../hooks/useLogout'
import Avatar_anonymous from '../assets/avatar.png'
import MenuIcon from '@mui/icons-material/Menu'
import useRefreshToken from '../hooks/useRefreshToken'
import useAxios from '../hooks/useAxios'

const Layout = () => {
  const [mobileOpen, setMobileOpen] = useState(false)
  const [outletLoading, setOutletLoading] = useState(false)
  const drawerWidth = '14rem'
  const { account } = useAccount()
  const location = useLocation()
  const logout = useLogout()
  const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const refresh = useRefreshToken()
  const axiosInstance = useAxios()

  const signOut = async () => {
    await logout()
  }

  useEffect(() => {
    window.addEventListener('focus', onFocus)
    return () => {
      window.removeEventListener('focus', onFocus)
    }
    // eslint-disable-next-line
  }, [location])

  const onFocus = () => {
    const clientTime = Date.now()
    if (clientTime >= account?.expiration * 1000) {
      setOutletLoading(true)
      refresh(axiosInstance)
    } else {
      setOutletLoading(false)
    }
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const menuData = [
    {
      id: 1,
      title: 'Dashboard',
      path: '/',
      icon: 'home-outline',
      permission: 'dashboard_read',
    },
    {
      id: 2,
      title: 'Účty',
      path: '/tenants',
      icon: 'business-outline',
      permission: 'tenants_read',
    },
    {
      id: 3,
      title: 'Uživatelé',
      path: '/users',
      icon: 'people-circle-outline',
      permission: 'users_read',
    },
    {
      id: 9,
      title: 'Docházka',
      path: '/attendance',
      icon: 'calendar-number-outline',
      permission: 'attendance_read',
    },
    {
      id: 10,
      title: 'Zaměstnanci',
      path: '/employees',
      icon: 'people-outline',
      permission: 'employees_read',
    },
    {
      id: 5,
      title: 'Aktuality',
      path: '/news',
      icon: 'newspaper-outline',
      permission: 'news_read',
    },
    {
      id: 6,
      title: 'Nastavení',
      path: '/settings',
      icon: 'settings-outline',
      permission: 'settings_read',
    },
  ]
  let clearedMenu = []
  // eslint-disable-next-line
  menuData.map((value) => {
    if (account.activeTenant.permissions.includes(value.permission)) {
      clearedMenu.push(value)
    }
  })

  const handleChangeTenant = (e) => {
    document.cookie = `tenant=${e.target.value};max-age=${account.expiration}`
    refresh(axiosInstance)
    window.location.reload()
  }

  const drawer = (
    <Box
      sx={{
        borderRight: (theme) => theme.palette.sidebar.border,
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        width: drawerWidth,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {account?.activeTenant.logo_filename && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '5rem',
          }}
        >
          <Box
            component="img"
            src={`upload/tenants/${account.activeTenant.logo_filename}`}
            sx={{
              height: '3rem',
            }}
          />
        </Box>
      )}

      <Box overflow={'auto'} flexGrow={1}>
        {account.tenants.length > 1 ? (
          <FormControl
            sx={{ my: 1, mx: 2, minWidth: 120, display: 'flex' }}
            size="small"
          >
            <InputLabel id="demo-select-small">Účet</InputLabel>
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              value={account.activeTenant.tenant_id}
              label="Tenant"
              onChange={handleChangeTenant}
            >
              {account.tenants.map((item, i) => (
                <MenuItem key={i} value={item.tenant_id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : undefined}
        <List>
          {clearedMenu.map((item) => (
            <Link
              key={item.id}
              to={item.path}
              onClick={() => setMobileOpen()}
              component={RouterLink}
              sx={{
                textDecoration: 'none',
                color: (theme) => theme.palette.sidebar.link,
                ':hover': {
                  color: (theme) => theme.palette.sidebar.hoverLink,
                },
              }}
            >
              <ListItemButton
                selected={item.path === location.pathname}
                sx={{
                  ':hover': {
                    backgroundColor: (theme) =>
                      theme.palette.sidebar.hoverLinkBackground,
                  },
                  '&.Mui-selected': {
                    color: (theme) => theme.palette.sidebar.hoverLink,
                  },
                }}
              >
                <Box
                  sx={{
                    pl: '0.6rem',
                    pr: '0.8rem',
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '1.3rem',
                  }}
                >
                  <ion-icon name={item.icon}></ion-icon>
                </Box>
                <ListItemText
                  primary={item.title}
                  sx={{
                    '& .MuiTypography-root': {
                      fontWeight: '500',
                      fontSize: '1rem',
                    },
                  }}
                />
              </ListItemButton>
            </Link>
          ))}

          {/* Logout link */}
          <Link
            key={0}
            onClick={signOut}
            sx={{
              textDecoration: 'none',
              color: (theme) => theme.palette.sidebar.link,
              ':hover': { color: (theme) => theme.palette.sidebar.hoverLink },
            }}
          >
            <ListItemButton>
              <Box
                sx={{
                  pl: '0.7rem',
                  pr: '0.7rem',
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '1.3rem',
                }}
              >
                <ion-icon name="log-out-outline"></ion-icon>
              </Box>
              <ListItemText
                primary="Odhlásit"
                sx={{
                  '& .MuiTypography-root': {
                    fontWeight: '500',
                    fontSize: '1rem',
                  },
                }}
              />
            </ListItemButton>
          </Link>
        </List>
      </Box>
    </Box>
  )

  return !outletLoading ? (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        position="fixed"
        sx={{
          borderBottom: (theme) => theme.palette.appbar.border,
          boxShadow: (theme) => theme.palette.appbar.shadow,
          backgroundColor: (theme) => theme.palette.appbar.background,
        }}
      >
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Box
            sx={{
              display: { xs: 'none', md: 'flex' },
            }}
          />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: { xs: 'row', md: 'row-reverse' },
            }}
          >
            <Avatar
              src={Avatar_anonymous}
              sx={{
                mx: 1,
                height: '3rem',
                width: '3rem',
                border: (theme) => theme.palette.appbar.avatarBorder,
                boxShadow: (theme) => theme.palette.appbar.avatarShadow,
              }}
            />
            <Box
              sx={{
                textAlign: { md: 'right', xs: 'left' },
              }}
            >
              <Box
                sx={{
                  pr: '0.5rem',
                  color: (theme) => theme.palette.appbar.nameColor,
                  fontSize: '0.85rem',
                  fontWeight: '400',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: { xs: '11rem', sm: 'auto' },
                }}
              >
                {account.name} {account.lastname}
              </Box>
              <Box
                sx={{
                  pr: '0.5rem',
                  color: (theme) => theme.palette.appbar.tenantColor,
                  fontSize: '0.75rem',
                  fontWeight: '600',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  maxWidth: { xs: '11rem', sm: 'auto' },
                }}
              >
                {account.activeTenant.name}
              </Box>
            </Box>
          </Box>
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{
              ml: 2,
              display: { md: 'none' },
            }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {isMobileScreen ? (
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': {
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              width: drawerWidth,
              backgroundColor: (theme) => theme.palette.sidebar.background,
            },
          }}
        >
          {drawer}
        </Drawer>
      ) : (
        <Drawer
          variant="permanent"
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'none', md: 'block' },
            '& .MuiDrawer-paper': {
              backgroundColor: (theme) => theme.palette.sidebar.background,
              boxShadow: (theme) => theme.palette.sidebar.shadow,
              width: drawerWidth,
              borderRight: 0,
            },
          }}
        >
          {drawer}
        </Drawer>
      )}

      <Box
        sx={{
          width: { md: drawerWidth },
          flexShrink: { sm: 0 },
        }}
      ></Box>
      <Box
        sx={{
          flexGrow: 1,
          p: { xs: '1rem', md: '1.2rem', lg: '2rem' },
          width: { md: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  ) : (
    <Box
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      height={'70vh'}
    >
      <CircularProgress />
    </Box>
  )
}

export default Layout
