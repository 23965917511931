import axios from 'axios'

let baseURL = '/api/'

if (process.env.NODE_ENV === 'development') {
  baseURL = 'http://localhost:8080/api/'
}

const axiosInstance = axios.create({
  baseURL: baseURL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  withCredentials: true,
})

export default axiosInstance
