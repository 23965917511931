import React, { lazy, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import PrivateRoute from './components/PrivateRoute'
import PersistentLogin from './components/PersistentLogin'
import Layout from './layout/Layout'

const Attendance = lazy(() => import('./pages/Attendance/Attendance'))
const Employees = lazy(() => import('./pages/Employees/Employees'))
const Tenants = lazy(() => import('./pages/Tenants/Tenants'))
const Users = lazy(() => import('./pages/Users/Users'))
const News = lazy(() => import('./pages/News/News'))
const Home = lazy(() => import('./pages/Home'))
const Login = lazy(() => import('./Login'))
const NotFound = lazy(() => import('./pages/NotFound'))

const App = () => {
  return (
    <Routes>
      {/* Private routes */}
      <Route element={<PersistentLogin />}>
        <Route element={<PrivateRoute />}>
          <Route path="/" element={<Layout />}>
            <Route
              index
              element={
                <Suspense fallback={<div>Loading ...</div>}>
                  <Home />
                </Suspense>
              }
            />
            <Route
              path="/news"
              element={
                <Suspense fallback={<div>Loading ...</div>}>
                  <News />
                </Suspense>
              }
            />
            <Route
              path="/tenants"
              element={
                <Suspense fallback={<div>Loading ...</div>}>
                  <Tenants />
                </Suspense>
              }
            />
            <Route
              path="/users"
              element={
                <Suspense fallback={<div>Loading ...</div>}>
                  <Users />
                </Suspense>
              }
            />
            <Route
              path="/attendance"
              element={
                <Suspense fallback={<div>Loading ...</div>}>
                  <Attendance />
                </Suspense>
              }
            />
            <Route
              path="/employees"
              element={
                <Suspense fallback={<div>Loading ...</div>}>
                  <Employees />
                </Suspense>
              }
            />
            {/* Route not found */}
            <Route
              path="*"
              element={
                <Suspense fallback={<div>Loading ...</div>}>
                  <NotFound />
                </Suspense>
              }
            />
          </Route>
        </Route>
      </Route>

      {/* Public routes */}

      <Route
        path="/login"
        element={
          <Suspense fallback={<div>Loading ...</div>}>
            <Login />
          </Suspense>
        }
      />
    </Routes>
  )
}

export default App
