import { createContext, useState } from 'react'

const AccountContext = createContext({})

export const AccountProvider = ({ children }) => {
  const [account, setAccount] = useState({})

  return (
    <AccountContext.Provider value={{ account, setAccount }}>
      {children}
    </AccountContext.Provider>
  )
}

export default AccountContext
