import React from 'react'
import { useLocation, Navigate, Outlet } from 'react-router-dom'
import useAccount from '../hooks/useAccount'

const PrivateRoute = () => {
  const { account } = useAccount()
  const location = useLocation()

  return account?.accessToken ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  )
}

export default PrivateRoute
